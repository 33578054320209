import React from "react";
import styles from "./InformeVentas.module.scss";
import RrppList from "../../../Components/RrppList/RrppList";

// Función auxiliar para formatear moneda
const formatCurrency = (value) => {
    return new Intl.NumberFormat("es-AR", {
        style: "currency",
        currency: "ARS",
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    }).format(value);
};

// Función proporcionada para formatear fechas
const formatDate = (dateString) => {
    const [datePart, timePart] = dateString.split(" ");
    const [day, month, year] = datePart.split("/");
    const [hour, minute] = timePart.split(":");
    const date = new Date(year, month - 1, day, hour, minute);
    return `${date.getDate()} ${date.toLocaleString("default", {
        month: "short",
    })} ${date.getFullYear()}`;
};

const InformeVentas = ({ datos, rrpps }) => {
    if (!datos) {
        return <div style={{ color: "red" }}>No se encontró ningún evento.</div>;
    }

    // Calcular las comisiones basadas en el tipo de distribución de pagos
    let comisiones = 0;
    let extraCharge = 0;
    datos.cantidadTicketsVendidos.forEach(ticket => {
        // Calcular el cargo extra por las ventas directas
        extraCharge += ticket.cantidadEnviadaDirectamente * (ticket.precio * 0.10);
    });

    let aLiquidar = datos.ventasTotales;

    // Aplicar el porcentaje de comisión según la distribución de pagos y ajustar el monto a liquidar
    switch (datos.distribucionDePagos) {
        case "20-0":
            comisiones = datos.ventasTotales * 0.2;
            break;
        case "DIRECTO":
        case "BASICO":
        case "15-0":
            comisiones = datos.ventasTotales * 0.15;
            break;
        case "10-15":
            comisiones = datos.ventasTotales * 0.10;
            aLiquidar = (datos.ventasTotales * 0.85);
            break;
        default:
            break;
    }

    // Ajustar el monto a liquidar para incluir el cargo extra
    aLiquidar -= extraCharge;
    const ventasTotales = datos.ventasTotales + comisiones;

    return (
        <div className={styles.body}>
            <h1 className={styles.InformeVentasH1}>
                Informe de ventas <br /> <span>{datos.tituloEvento}</span> -{" "}
                <span>{formatDate(datos.fechaRealizazion)}</span>
            </h1>

            <div className={styles.seccionCards}>
                <div className={`${styles.cardVentas} ${styles.cardVentasTotales}`}>
                    <h5>Ventas totales</h5>
                    <p>{formatCurrency(ventasTotales)}</p>
                </div>
                <div className={`${styles.cardVentas} ${styles.cardVentaALiquidar}`}>
                    <h5>A liquidar por ventas*:</h5>
                    <p>{formatCurrency(aLiquidar)}</p>
                </div>
                <div className={`${styles.cardVentas} ${styles.cardVentaComisiones}`}>
                    <h5>Comisiones de simplepass:</h5>
                    <p>{formatCurrency(comisiones)}</p>
                </div>
            </div>
            <p className={styles.aclaracion}>
                *"A liquidar por ventas" es el monto neto que el productor obtiene del
                evento, una vez descontadas las comisiones.
            </p>

            {/* Tabla de Tickets Vendidos */}
            {datos.cantidadTicketsVendidos &&
                datos.cantidadTicketsVendidos.length > 0 && (
                    <section className={styles.seccionDeInforme}>
                        <h2>Cantidades de tickets vendidos</h2>
                        <table className={styles.tablaInformeCantidades}>
                            <thead>
                                <tr>
                                    <th className={styles.brtopleft}>Tipo</th>
                                    <th className={styles.br0}>Cantidad</th>
                                    <th className={styles.br0}>Precio</th>
                                    <th className={styles.brtopright}>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {datos.cantidadTicketsVendidos.map((ticket, index) => (
                                    <tr key={index}>
                                        <td className={styles.brbottomleft}>{ticket.nombreTipoTicket}</td>
                                        <td>{ticket.cantidadVendida}</td>
                                        <td>{formatCurrency(ticket.precio)}</td>
                                        <td className={styles.brbottomright}>{formatCurrency(ticket.total)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </section>
                )}

            {/* Tabla de Tickets Enviados Directamente */}
            {datos.cantidadTicketsVendidos && datos.cantidadTicketsVendidos.some(ticket => ticket.cantidadEnviadaDirectamente > 0) && (
                <section className={styles.seccionDeInforme}>
                    <h2>Tickets enviados directamente</h2>
                    <table className={styles.tablaInformeCantidades}>
                        <thead>
                            <tr>
                                <th className={styles.brtopleft}>Tipo</th>
                                <th className={styles.brtopright}>Cantidad QRs</th>
                            </tr>
                        </thead>
                        <tbody>
                            {datos.cantidadTicketsVendidos.filter(ticket => ticket.cantidadEnviadaDirectamente > 0).map((ticket, index) => (
                                <tr key={index}>
                                    <td className={styles.brbottomleft}>{ticket.nombreTipoTicket}</td>
                                    <td className={styles.brbottomright}>{ticket.cantidadEnviadaDirectamente}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </section>
            )}

            {/* Tabla de Butacas Vendidas */}
            {datos.resumenButacasVendidas && datos.resumenButacasVendidas.length > 0 && (
                <section className={styles.seccionDeInforme}>
                    <h2>Cantidades de butacas vendidas</h2>
                    <table className={styles.tablaInformeCantidades}>
                        <thead>
                            <tr>
                                <th className={styles.brtopleft}>Sección</th>
                                <th className={styles.br0}>Fila</th>
                                <th className={styles.br0}>Cantidad</th>
                                <th className={styles.br0}>Precio</th>
                                <th className={styles.brtopright}>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {datos.resumenButacasVendidas.map((butaca, index) => (
                                <tr key={index}>
                                    <td>{butaca.seccion}</td>
                                    <td>{butaca.fila}</td>
                                    <td>{butaca.totalVendidas}</td>
                                    <td>{formatCurrency(butaca.precio)}</td>
                                    <td>{formatCurrency(butaca.total)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </section>
            )}

            {/* Tabla de Mesas Vendidas */}
            {datos.resumenMesasVendidas && datos.resumenMesasVendidas.length > 0 && (
                <section className={styles.seccionDeInforme}>
                    <h2>Cantidades de mesas vendidas</h2>
                    <table className={styles.tablaInformeCantidades}>
                        <thead>
                            <tr>
                                <th className={styles.brtopleft}>Nombre</th>
                                <th className={styles.br0}>Cantidad</th>
                                <th className={styles.br0}>Precio</th>
                                <th className={styles.brtopright}>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {datos.resumenMesasVendidas.map((mesa, index) => (
                                <tr key={index}>
                                    <td>{mesa.nombre}</td>
                                    <td>{mesa.totalVendidas}</td>
                                    <td>{formatCurrency(mesa.precio)}</td>
                                    <td>{formatCurrency(mesa.total)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </section>
            )}

            {/* Tabla de Consumiciones Vendidas */}
            {datos.resumenProductosConsumidos && datos.resumenProductosConsumidos.length > 0 && (
                <section className={styles.seccionDeInforme}>
                    <h2>Cantidades de consumiciones vendidas</h2>
                    <table className={styles.tablaInformeCantidades}>
                        <thead>
                            <tr>
                                <th className={styles.brtopleft}>Consumición</th>
                                <th className={styles.br0}>Cantidad</th>
                                <th className={styles.br0}>Precio</th>
                                <th className={styles.brtopright}>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {datos.resumenProductosConsumidos.map((producto, index) => (
                                <tr key={index}>
                                    <td>{producto.nombreProducto}</td>
                                    <td>{producto.cantidad}</td>
                                    <td>{formatCurrency(producto.precio)}</td>
                                    <td>{formatCurrency(producto.cantidad * producto.precio)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </section>
            )}

            {/* Lista de RRPPs si existen */}
            {rrpps && rrpps.length > 0 && <RrppList rrpps={rrpps} />}

        </div>
    );
};

export default InformeVentas;