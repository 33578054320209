import React, { useRef } from 'react';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import styles from './ButacaTicket.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';

const ButacaTicket = ({ butaca }) => {
    const ticketRef = useRef(null);

    const descargarEntradaPDF = async () => {
        if (ticketRef.current) {
            const canvas = await html2canvas(ticketRef.current, { allowTaint: true, useCORS: true });
            const imgData = canvas.toDataURL('image/png');

            // Crear instancia de jsPDF ('p' para formato vertical, 'mm' para unidades en milímetros, y 'a4' para tamaño de papel)
            const pdf = new jsPDF('p', 'mm', 'a4');

            // Agregar imagen al PDF. Los argumentos son (imagen, formato, x, y, ancho, alto)
            pdf.addImage(imgData, 'PNG', 10, 10, 180, 160); // Ajusta los valores para que se adapten al tamaño del contenido

            // Descargar el PDF generado
            pdf.save(`BUTACA-ID:${butaca.id}.pdf`);
        }
    };

    // Genera query string para evitar caché en imágenes
    const randomQueryStringVerificado = "?nocache=" + new Date().getTime();
    const srcVerificado = "https://simplepassbucket.s3.sa-east-1.amazonaws.com/img/Tickets/Verified.svg.png" + randomQueryStringVerificado;

    return (
        <div ref={ticketRef} className={styles.ticketCont}>
            <div className={styles.logoTicket}>
                <div className={styles.detallesTicket}>
                    <p className={styles.textoVerificado}>
                        TICKET DE BUTACA VERIFICADO <img src={srcVerificado} alt="Verificado" crossOrigin="anonymous" />
                    </p>
                    <p className={styles.id}>ID: {butaca.id.slice(0,7)+"..."}</p>
                </div>
                <div className={styles.contTitulo}>
                    <h1>simple<span>pass</span></h1>
                </div>
            </div>

            <div className={styles.datosTicket}>
                <h2>{butaca.nombreEvento}</h2>
                <span>Sección: {butaca.seccion}</span>
                <span>Fila: {butaca.fila}</span>
                <span>Número de Butaca: {butaca.numeroButaca}</span>
                <span>Fecha del Evento: {butaca.fechaEvento}</span>
            </div>

            <div className={styles.imagenQR}>
                <img src={butaca.urlImgQr + "?nocache=" + new Date().getTime()} alt="Código QR" crossOrigin="anonymous" />
            </div>

            <button className={styles.btnDescargar} onClick={descargarEntradaPDF}>
                Descargar <FontAwesomeIcon icon={faDownload} />
            </button>
        </div>
    );
};

export default ButacaTicket;