import React, { useState, useEffect, useCallback } from 'react';
import Teatro3deFebrero from '../Teatro3DeFebrero/Teatro3deFebrero';
import styles from "./SeccionButacas.module.scss";

const SeccionButacas = ({ evento, onUpdateSelectedSeats }) => {
  const [asientosSeleccionados, setAsientosSeleccionados] = useState([]);

  // Optimización con useCallback
  const handleAsientosSeleccionados = useCallback((selectedSeats) => {
    setAsientosSeleccionados(selectedSeats);
  }, []);

  // Actualiza el estado en el componente padre
  useEffect(() => {
    if (onUpdateSelectedSeats) {
      onUpdateSelectedSeats(asientosSeleccionados);
    }
  }, [asientosSeleccionados, onUpdateSelectedSeats]);

  return (
    <div className={styles.bodyButacas}>
      <div className={styles.header}>
        <h4>Butacas</h4>
        <p>Elige la sección y asientos que prefieras dentro del mapa</p>
      </div>

      {(evento.id === "874377791117853669" || evento.id === "8078739981077" || evento.id === "7574007933657") && (
        <Teatro3deFebrero evento={evento} onSeatsSelected={handleAsientosSeleccionados} />
      )}
    </div>
  );
};

export default SeccionButacas;