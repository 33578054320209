import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import styles from "./GestionTipoDeTicketsPage.module.scss";
import variables from "../../Context/Variables";
import SubmitButton from '../../Components/Buttons-loaders-inputs/SubmitButton/SubmitButton';
import TipoDeTicketSkeleton from './Skeleton/TipoDeTicketSkeleton';
import NoPermissionPage from '../../Components/NoPermissionPage/NoPermissionPage';
import { useAuth } from '../../Context/AuthProvider';

const GestionTipoDeTicketsPage = () => {
  const { eventoId, nombre } = useParams();
  const navigate = useNavigate();
  const [tiposTickets, setTiposTickets] = useState([]);
  const [erroresTiposTickets, setErroresTiposTickets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [newTickets, setNewTickets] = useState([]);
  const [modifiedTickets, setModifiedTickets] = useState([]);
  const [deletedTickets, setDeletedTickets] = useState([]);
  const [cargandoTickets, setCargandoTicket] = useState(true);
  const [hasPermission, setHasPermission] = useState(true);
  const { estaAutenticado, jwt } = useAuth();

  useEffect(() => {
    document.title = "Administrar venta de tickets";
    window.scrollTo(0, 0);
    ensureAuthenticated();
    fetchTiposDeTickets(jwt);
  }, [eventoId, navigate]);

  const ensureAuthenticated = () => {
    const userDetails = sessionStorage.getItem('userDetails') || localStorage.getItem('userDetails');
    if (!userDetails) {
      navigate("/login");
    } else {
      const details = JSON.parse(userDetails);
      if (details.rol !== "PRODUCTOR") {
        setHasPermission(false);
      }
    }
  };

  const fetchTiposDeTickets = async (jwt) => {
    try {
      setCargandoTicket(true);
      const response = await axios.get(`${variables.API_BASE_URL}/api/tdt/evento/${eventoId}/tipos-de-tickets`, {
        headers: { Authorization: `Bearer ${jwt}` }
      });
  
      if (response.status === 401) {
        setHasPermission(false);
        return;
      }
  
      const tickets = response.data.map(t => ({
        ...t,
        nombre: t.nombre || '',
        precio: t.precio ? t.precio.toString() : '',
        cantidadLimite: t.cantidadLimite ? t.cantidadLimite.toString() : '',
        estaDisponible: t.estaDisponible || false
      }));
  
      // Ordenar los tickets alfabéticamente por nombre
      tickets.sort((a, b) => a.nombre.localeCompare(b.nombre));
  
      setTiposTickets(tickets);
      setErroresTiposTickets(tickets.map(() => ({ nombre: '', precio: '', cantidadLimite: '' })));
      setCargandoTicket(false);
    } catch (error) {
      Swal.fire('Error', 'No se pudo cargar los tipos de tickets.', 'error');
      setCargandoTicket(false);
    }
  };

  const validarCampo = (value, type) => {
    switch (type) {
      case 'nombre':
        return value.trim().length >= 3;
      case 'precio':
      case 'cantidadLimite':
        return !isNaN(value) && Number(value) > 0;
      default:
        return false;
    }
  };

  const handleInputChange = (index, valor, campo) => {
    const updatedTickets = [...tiposTickets];
    updatedTickets[index] = { ...updatedTickets[index], [campo]: valor };

    const isValid = validarCampo(valor, campo);

    const updatedErrors = [...erroresTiposTickets];
    updatedErrors[index][campo] = isValid ? '' : `El campo ${campo} es inválido.`;

    setTiposTickets(updatedTickets);
    setErroresTiposTickets(updatedErrors);

    if (!updatedTickets[index].id || updatedTickets[index].id.startsWith("new-")) {
      const newIdx = newTickets.findIndex(ticket => ticket.id === updatedTickets[index].id);
      if (newIdx === -1) {
        setNewTickets([...newTickets, updatedTickets[index]]);
      } else {
        const updatedNewTickets = [...newTickets];
        updatedNewTickets[newIdx] = updatedTickets[index];
        setNewTickets(updatedNewTickets);
      }
    } else if (updatedTickets[index].canidadVendida === 0) {
      const modIdx = modifiedTickets.findIndex(ticket => ticket.id === updatedTickets[index].id);
      if (modIdx === -1) {
        setModifiedTickets([...modifiedTickets, updatedTickets[index]]);
      } else {
        const updatedModifiedTickets = [...modifiedTickets];
        updatedModifiedTickets[modIdx] = updatedTickets[index];
        setModifiedTickets(updatedModifiedTickets);
      }
    }
  };

  const canSubmit = () => {
    const noErrors = erroresTiposTickets.every(err => !Object.values(err).some(v => v));
    const allFieldsValid = tiposTickets.every((ticket) => {
      return validarCampo(ticket.nombre, 'nombre') && validarCampo(ticket.precio, 'precio') && validarCampo(ticket.cantidadLimite, 'cantidadLimite');
    });
    const hasValidChanges = newTickets.length > 0 || modifiedTickets.length > 0 || deletedTickets.length > 0;
    return noErrors && allFieldsValid && hasValidChanges;
  };

  const handleSubmit = async () => {
    if (!canSubmit()) {
      Swal.fire('Error', 'Por favor, corrija los errores en los formularios antes de enviar.', 'error');
      return;
    }
    setIsLoading(true);
    try {
      await axios.post(`${variables.API_BASE_URL}/api/tdt/evento/${eventoId}/actualizar-tipos-tickets`, {
        newTickets, modifiedTickets, deletedTickets
      }, {
        headers: { Authorization: `Bearer ${jwt}` }
      });
      Swal.fire('Éxito', 'Los tipos de tickets han sido actualizados correctamente.', 'success');
      window.location.reload();
    } catch (error) {
      Swal.fire('Error', 'No se pudieron guardar los cambios.', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDisponibilidadChange = (index) => {
    const updatedTickets = [...tiposTickets];
    updatedTickets[index] = { ...updatedTickets[index], estaDisponible: !updatedTickets[index].estaDisponible };
    setTiposTickets(updatedTickets);
  
    if (!updatedTickets[index].id.startsWith("new-")) {
      const modIdx = modifiedTickets.findIndex(ticket => ticket.id === updatedTickets[index].id);
      if (modIdx === -1) {
        setModifiedTickets([...modifiedTickets, updatedTickets[index]]);
      } else {
        const updatedModifiedTickets = [...modifiedTickets];
        updatedModifiedTickets[modIdx] = updatedTickets[index];
        setModifiedTickets(updatedModifiedTickets);
      }
    } else {
      const newIdx = newTickets.findIndex(ticket => ticket.id === updatedTickets[index].id);
      if (newIdx === -1) {
        setNewTickets([...newTickets, updatedTickets[index]]);
      } else {
        const updatedNewTickets = [...newTickets];
        updatedNewTickets[newIdx] = updatedTickets[index];
        setNewTickets(updatedNewTickets);
      }
    }
  };
  

  const agregarTipoTicket = () => {
    const nuevoTipoTicket = {
      id: "new-" + new Date().getTime(),
      nombre: "",
      precio: "",
      cantidadLimite: "",
      estaDisponible: false
    };
    setTiposTickets(prev => [...prev, nuevoTipoTicket]);
    setErroresTiposTickets(prev => [...prev, { nombre: "", precio: "", cantidadLimite: "" }]);
    setNewTickets(prev => [...prev, nuevoTipoTicket]);
  };  

  const eliminarTipoTicket = (index) => {
    const ticket = tiposTickets[index];
    if (ticket.canidadVendida > 0) {
      return; // No permitir eliminar si cantidadVendida es mayor a 0
    }
    const updatedTickets = tiposTickets.filter((_, idx) => idx !== index);
    setTiposTickets(updatedTickets);

    if (ticket.id.startsWith("new-")) {
      setNewTickets(newTickets.filter(t => t.id !== ticket.id));
    } else {
      setDeletedTickets([...deletedTickets, ticket]);
      setModifiedTickets(modifiedTickets.filter(t => t.id !== ticket.id));
    }

    const updatedErrors = erroresTiposTickets.filter((_, idx) => idx !== index);
    setErroresTiposTickets(updatedErrors);
  };

  if (!hasPermission) {
    return <NoPermissionPage />;
  }

  return (
    <div className={styles.body}>
      <h1 className={styles.titulo}>Administrar tickets a la venta</h1>
      <h2 className={styles.subtitulo}>Evento: <span>{nombre}</span></h2>
      <div className={styles.fondoSub}>
        <p>
          Desde acá podés <b>editarlos</b>, <b>detener su venta</b>, <b>eliminarlos</b> o{" "}
          <b>agregar nuevos.</b>
        </p>
      </div>
      {cargandoTickets ? (
        <div className={styles.tiposDeTickets}>
          <TipoDeTicketSkeleton />
          <TipoDeTicketSkeleton />
        </div>
      ) : (
        <div className={styles.tiposDeTickets}>
          {tiposTickets.map((tipo, index) => {
            const ticketVendido = tipo.canidadVendida > 0;

            return (
              <div className={styles.tipoDeTicket} key={index}>
                <p className={styles.numeroTipoTicket}>
                  Tipo de ticket {index + 1}: {" "}<span>{tipo.nombre}</span>
                </p>

                <label className={styles.nombreLabel}>Nombre del ticket:</label>
                <input
                  type="text"
                  placeholder='Ejemplo: "General primera tanda"'
                  value={tipo.nombre}
                  maxLength={30}
                  onChange={(e) => handleInputChange(index, e.target.value, 'nombre')}
                  className={erroresTiposTickets[index]?.nombre ? styles.borderInvalid : styles.borderValid}
                />
                {erroresTiposTickets[index]?.nombre && (
                  <p className={styles.error}>
                    {erroresTiposTickets[index].nombre}
                  </p>
                )}

                <label>Precio <small>(sin signo $)</small>:</label>
                <input
                  type="number"
                  placeholder="Ejemplo: 500"
                  value={tipo.precio}
                  onChange={(e) => handleInputChange(index, e.target.value, 'precio')}
                  className={erroresTiposTickets[index]?.precio ? styles.borderInvalid : styles.borderValid}
                  disabled={ticketVendido}
                />
                {erroresTiposTickets[index]?.precio && (
                  <p className={styles.error}>
                    {erroresTiposTickets[index].precio}
                  </p>
                )}

                <label>Cantidad máxima de venta:</label>
                <input
                  type="number"
                  placeholder="Cantidad Límite"
                  value={tipo.cantidadLimite}
                  onChange={(e) => handleInputChange(index, e.target.value, 'cantidadLimite')}
                  className={erroresTiposTickets[index]?.cantidadLimite ? styles.borderInvalid : styles.borderValid}
                  disabled={ticketVendido}
                />
                {erroresTiposTickets[index]?.cantidadLimite && (
                  <p className={styles.error}>
                    {erroresTiposTickets[index].cantidadLimite}
                  </p>
                )}

                <label>Disponible a la venta:</label>
                <div className={styles.checkboxApple}>
                  <input
                    className={styles.yep}
                    id={`checkApple-${index}`}
                    type="checkbox"
                    checked={tipo.estaDisponible}
                    onChange={() => handleDisponibilidadChange(index)}
                  />
                  <label className={styles.labelToggle} htmlFor={`checkApple-${index}`}></label>
                </div>

                {ticketVendido && (
                  <p style={{ color: "red", border: "solid 1px red", borderRadius: "10px", padding: "4px", textAlign: "center", fontSize: "12px", marginBottom: "16px" }} className={styles.mensajeVendida}>
                    Este ticket ya comenzó a venderse y no se puede eliminar o modificar el precio o cantidad.
                  </p>
                )}
                {!ticketVendido && (
                  <button
                    className={styles.eliminarBtn}
                    type="button"
                    onClick={() => eliminarTipoTicket(index)}
                  >
                    Eliminar tipo de ticket <FontAwesomeIcon icon={faMinus} />
                  </button>
                )}
              </div>
            );
          })}

          <button
            className={styles.agregarBtn}
            type="button"
            onClick={agregarTipoTicket}
          >
            Agregar tipo de ticket <FontAwesomeIcon icon={faPlus} />
          </button>
        </div>
      )}

      <div className={styles.contBtnGuardar}>
        <SubmitButton
          type="submit"
          isDisabled={!canSubmit()}
          color="#252525"
          isLoading={isLoading}
          onClick={handleSubmit}
        >
          Guardar cambios <FontAwesomeIcon icon={faFloppyDisk} />
        </SubmitButton>
      </div>
    </div>
  );
};

export default GestionTipoDeTicketsPage;
