import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import './App.css'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import ReactGA from 'react-ga';
import HomePage from "./Pages/index/Home";
import LoginPage from "./Pages/loguin/LoginPage";
import Layout from "./Components/Layout/Layout";
import SearchResults from "./Pages/resultados-busqueda/SearchResults";
import Faq from "./Pages/faq/Faq";
import Nosotros from "./Pages/nosotros/Nosotros";
import InfoCrearEvento from "./Pages/crearEvento/InfoCrearEvento";
import EventoPage from "./Pages/evento/EventoPage";
import PageTransition from './Components/PageTransition/PageTransition';
import NotFoundPage from "./Components/NotFoundPage/NotFoundPage";
import Perfil from "./Pages/perfil/Perfil";
import MisTickets from "./Pages/mis-tickets/MisTickets";
import MisEventos from "./Pages/mis-eventos/MisEventos";
import MisDatos from "./Pages/mis-datos/MisDatos";
import TerminosYCondiciones from "./Pages/terminosYCondiciones/TerminosYCondiciones";
import ContectarCuentasMercadoPago from "./Pages/conectar-cuentas-mp/ContectarCuentasMercadoPago";
import ExitoCreacionEvento from "./Pages/exito-vinculacion-mp/ExitoCreacionEvento";
import ResetPasswordForm from "./Pages/recuperar-contraseña/ResetPasswordForm";
import CambiarContraseña from "./Pages/cambiar-contraseña/CambiarContraseña";
import CuerpoTicketsConsumiciones from "./Pages/mis-consumiciones/CuerpoTicketsConsumiociones";
import TicketScanner from "./Pages/scanner-ticket/TicketScanner";
import ConsumicionScanner from "./Pages/scanner-consumiciones/ConsumicionScanner";
import ConfiguracionFinanzas from "./Pages/configuracion-finanzas/ConfiguracionFinanzas";
import PanelProductor from "./Pages/panel-productor/PanelProductor";
import EditarEventoPage from "./Pages/editar-evento/EditarEventoPage";
import InformeVentasPage from "./Pages/informe-ventas/InformeVentasPage";
import MisPagosPage from "./Pages/mis-pagos/MisPagosPage"
import DetallePagoExito from "./Pages/exito/DetallePagoExito";
import CrearMenuPage from "./Pages/crear-menu/CrearMenuPage";
import GestionRrpps from "./Pages/gestion-rrpps/GestionRrpps";
import GestionRrrpEventoPage from "./Pages/gestion-rrpp-evento/GestionRrrpEventoPage";
import DetallePagoFallo from "./Pages/pago-fallido/DetallePagoFallo";
import EnviarQrsPage from "./Pages/generar-y-enviar-qr/EnviarQrsPage";
import EnvioDeQrForm from "./Components/EnvioDeQrForm/EnvioDeQrForm";
import GestionTipoDeTicketsPage from "./Pages/gestion-tickets/GestionTipoDeTicketsPage";
import SignUp from "./Pages/signup/SignUp";
import NuevaContraseñaPage from "./Pages/contraseña-nueva/NuevaContrasenaPage"
import TrabajaConNosotros from "./Pages/trabaja-con-nosotros/TrabajaConNosotros";
import GestionMesas from "./Pages/gestion-mesas/GestionMesas";
import CrearEvento from "./Pages/crear-evento/CrearEvento";
import Hotjar from "./Context/Hotjar";
import SignUpProductor from "./Pages/signup-productor/SignupProductor";
import EventoDetalle from "./Pages/evento-detalle/EventoDetalle";
import ExitoPago from "./Pages/exito-pago/ExitoPago";
import GestionStaff from "./Pages/gestion-staff/GestionStaff";
import GestionStaffEvento from "./Pages/gestion-staff-evento/GestionStaffEvento";
import EventoPagos from "./Pages/evento-pagos/EventoPagos";
import EventoConfiguracion from "./Pages/evento-configuracion/EventoConfiguracion";
import Cajas from "./Pages/cajas/Cajas";
import Caja from "./Pages/caja/Caja";
import ProductosCaja from "./Pages/productos-caja/ProductosCaja";
import ListaImpresoras from "./Pages/impresoras/ListaImpresoras";
import InformeCajaPage from "./Pages/informe-caja/InformeCajaPage";
import FiestaNacionalAvicultura from "./Pages/fiesta-nacional-avicultura/FiestaNacionalAvicultura";
import GeneradorMapaUbicaciones from "./Pages/generador-mapa-ubicaciones/GeneradorMapaUbicaciones";
import GestionButacas from "./Pages/gestion-butacas/GestionButacas";

const googleAnalyticsId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;

// Google analitycs 
ReactGA.initialize(googleAnalyticsId);
ReactGA.pageview(window.location.pathname + window.location.search);

function PageTracker() {
  let location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  return null;
}

function App() {

  // Configuracion hotjar
  useEffect(() => {
    Hotjar(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
  }, []);

  return (
    <Router>
      <PageTracker />
      <Routes>
        <Route path="signup" element={<PageTransition><SignUp /></PageTransition>} />
        <Route path="login" element={<PageTransition><LoginPage /></PageTransition>} />
        <Route path="generador-mapa-ubicaciones/:eventoId" element={<PageTransition><GeneradorMapaUbicaciones /></PageTransition>} />

        {/* Rutas con Layout */}
        <Route path="/" element={<Layout />}>
          <Route index element={<PageTransition><HomePage /></PageTransition>} />
          <Route path="index" element={<PageTransition><HomePage /></PageTransition>} />
          <Route path="resultados-busqueda" element={<PageTransition><SearchResults /></PageTransition>} />
          <Route path="faq" element={<PageTransition><Faq /></PageTransition>} />
          <Route path="editar-evento/:eventoId" element={<PageTransition><EditarEventoPage /></PageTransition>} />
          <Route path="perfil" element={<PageTransition><Perfil /></PageTransition>} />
          <Route path="evento/:id/:rrppId" element={<PageTransition><EventoPage /></PageTransition>} />
          <Route path="evento/:id" element={<PageTransition><EventoPage /></PageTransition>} />
          <Route path="crearEvento" element={<PageTransition><InfoCrearEvento /></PageTransition>} />
          <Route path="conectar-cuentas-mp/:eventoId" element={<PageTransition><ContectarCuentasMercadoPago /></PageTransition>} />
          <Route path="exito-vinculacion-mp" element={<PageTransition><ExitoCreacionEvento /></PageTransition>} />
          <Route path="crear-evento-plan-basico" element={<PageTransition><CrearEvento tipoPlan="BASICO" /></PageTransition>} />
          <Route path="crear-evento-plan-directo" element={<PageTransition><CrearEvento tipoPlan="DIRECTO" /></PageTransition>} />
          <Route path="configuracion-finanzas/:id" element={<PageTransition><ConfiguracionFinanzas /></PageTransition>} />
          <Route path="nosotros" element={<PageTransition><Nosotros /></PageTransition>} /> ///
          <Route path="gestion-rrpps" element={<PageTransition><GestionRrpps /></PageTransition>} />
          <Route path="terminosYCondiciones" element={<PageTransition><TerminosYCondiciones /></PageTransition>} />
          <Route path="mis-datos" element={<PageTransition><MisDatos /></PageTransition>} />
          <Route path="gestion-rrpp-evento/:eventoId/:nombre" element={<PageTransition><GestionRrrpEventoPage /></PageTransition>} />
          <Route path="crear-menu/:eventoId" element={<PageTransition><CrearMenuPage /></PageTransition>} />
          <Route path="mis-tickets" element={<PageTransition><MisTickets /></PageTransition>} />
          <Route path="mis-pagos" element={<PageTransition><MisPagosPage /></PageTransition>} />
          <Route path="scanner-ticket/:eventoId" element={<PageTransition><TicketScanner /></PageTransition>} />
          <Route path="scanner-consumiciones/:eventoId" element={<PageTransition><ConsumicionScanner /></PageTransition>} />
          <Route path="informe-ventas/:id" element={<PageTransition><InformeVentasPage /></PageTransition>} />
          <Route path="mis-eventos" element={<PageTransition><MisEventos /></PageTransition>} />
          <Route path="exito" element={<PageTransition><DetallePagoExito /></PageTransition>} />
          <Route path="pago-fallido" element={<PageTransition><DetallePagoFallo /></PageTransition>} />
          <Route path="gestion-tickets/:eventoId/:nombre" element={<PageTransition><GestionTipoDeTicketsPage /></PageTransition>} />
          <Route path="mis-consumiciones" element={<PageTransition><CuerpoTicketsConsumiciones /></PageTransition>} />
          <Route path="cambiar-contraseña" element={<PageTransition><CambiarContraseña /></PageTransition>} />
          <Route path="recuperar-contraseña" element={<PageTransition><ResetPasswordForm /></PageTransition>} />
          <Route path="contrasena-nueva/:token" element={<PageTransition><NuevaContraseñaPage /></PageTransition>} />
          <Route path="panel-productor" element={<PageTransition><PanelProductor /></PageTransition>} />
          <Route path="enviar-qrs/:eventoId" element={<PageTransition><EnviarQrsPage /></PageTransition>} />
          <Route path="generar-y-enviar-qr/:eventoId/:tipoDeTicketId/:nombreEvento/:nombreTicket" element={<PageTransition><EnvioDeQrForm /></PageTransition>} />
          <Route path="trabaja-con-nosotros" element={<PageTransition><TrabajaConNosotros /></PageTransition>} />
          <Route path="gestion-mesas/:eventoId" element={<PageTransition><GestionMesas /></PageTransition>} />
          <Route path="signup-productor" element={<PageTransition><SignUpProductor /></PageTransition>} />
          <Route path="evento-detalle/:id" element={<PageTransition><EventoDetalle /></PageTransition>} />
          <Route path="evento-detalle/:id/:rrppId" element={<PageTransition><EventoDetalle /></PageTransition>} />
          <Route path="pago-exitoso" element={<PageTransition><ExitoPago /></PageTransition>} />
          <Route path="gestion-staff" element={<PageTransition><GestionStaff /></PageTransition>} />
          <Route path="gestion-staff-evento/:eventoId/:nombre" element={<PageTransition><GestionStaffEvento /></PageTransition>} />
          <Route path="evento-pagos/:eventoId/:nombre" element={<PageTransition><EventoPagos /></PageTransition>} />
          <Route path="evento-configuracion/:eventoId/:nombre/:imgUrl" element={<PageTransition><EventoConfiguracion /></PageTransition>} />
          <Route path="evento-configuracion/:eventoId/:nombre" element={<PageTransition><EventoConfiguracion /></PageTransition>} />
          <Route path="cajas/:eventoId/:nombre" element={<PageTransition><Cajas /></PageTransition>} />
          <Route path="caja/:eventoId/:cajaId" element={<PageTransition><Caja /></PageTransition>} />
          <Route path="productos-caja/:eventoId/:cajaId" element={<PageTransition><ProductosCaja /></PageTransition>} />
          <Route path="impresoras" element={<PageTransition><ListaImpresoras /></PageTransition>} />
          <Route path="fiesta-nacional-avicultura" element={<PageTransition><FiestaNacionalAvicultura /></PageTransition>} />
          <Route path="informe-caja/:eventoId/:cajaId" element={<PageTransition><InformeCajaPage /></PageTransition>} />
          <Route path="gestion-butacas/:eventoId/:nombre" element={<PageTransition><GestionButacas /></PageTransition>} />

          {/* Ruta para manejar las páginas no encontradas */}
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;