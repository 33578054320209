import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Teatro3deFebrero from '../../MapasButacas/Teatro3DeFebrero/Teatro3deFebrero';
import variables from '../../Context/Variables';
import styles from "./GestionButacas.module.scss";
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import SubmitButton from '../../Components/Buttons-loaders-inputs/SubmitButton/SubmitButton';
import NoPermissionPage from '../../Components/NoPermissionPage/NoPermissionPage';
import GestionButacasSkeleton from './Skeleton/GestionButacasSkeleton';
import { useAuth } from '../../Context/AuthProvider';

const GestionButacas = () => {
  const { eventoId, nombre } = useParams();
  const [evento, setEvento] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedSeats, setSelectedSeats] = useState([]);
  const [descripcion, setDescripcion] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [bajas, setBajas] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [hasPermission, setHasPermission] = useState(true);
  const navigate = useNavigate();
  const { jwt } = useAuth();

  // Función para obtener el evento
  const fetchEvento = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${variables.API_BASE_URL}/api/eventos/${eventoId}`);
      setEvento(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch event data:", error);
      setError("No se pudo cargar el evento.");
      setLoading(false);
    }
  }, [eventoId]);

  const fetchBajas = async () => {
    try {
      const response = await axios.get(`${variables.API_BASE_URL}/api/tdt/evento/${eventoId}/bajas`, {
        headers: { Authorization: `Bearer ${jwt}` },
      });
      if (response.status === 200) {
        setBajas(response.data);
        setShowHistoryModal(true);
      } else {
        Swal.fire('Advertencia', 'No se pudo cargar el historial de bajas.', 'warning');
      }
    } catch (error) {
      console.error("Failed to fetch bajas:", error);
      Swal.fire('Error', 'Error interno al obtener bajas.', 'error');
    }
  };

  useEffect(() => {
    document.title = "Gestión de butacas";
    window.scrollTo(0, 0);
    ensureAuthenticated();
    fetchEvento();
  }, [fetchEvento]);

  const ensureAuthenticated = useCallback(() => {
    const userDetails = sessionStorage.getItem('userDetails') || localStorage.getItem('userDetails');
    if (!userDetails) {
      navigate("/login");
    } else {
      const details = JSON.parse(userDetails);
      if (details.rol !== "PRODUCTOR") {
        setHasPermission(false);
      }
    }
  }, [navigate]);

  const handleSeatsSelected = useCallback((seats) => {
    setSelectedSeats(seats);
  }, []);

  const handleDarDeBaja = useCallback(async () => {
    if (!descripcion.trim()) {
      Swal.fire('Error', 'Debes ingresar una descripción para dar de baja las butacas.', 'error');
      return;
    }

    setIsLoading(true);
    try {
      const seatIds = selectedSeats.map(seat => seat.id);

      const response = await axios.post(`${variables.API_BASE_URL}/api/tdt/dar-de-baja`, {
        eventoId: eventoId,
        butacaIds: seatIds,
        descripcion,
      }, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        }
      });

      if (response.status === 200) {
        Swal.fire('Éxito', 'Todas las butacas seleccionadas fueron dadas de baja correctamente.', 'success');
        setSelectedSeats([]);
        fetchEvento();
        setShowModal(false);
      } else if (response.status === 206) {
        Swal.fire('Advertencia', `${response.data}`, 'warning');
      } else if (response.status === 409) {
        Swal.fire('Error', `Ninguna de las butacas seleccionadas pudo darse de baja. ${response.data}`, 'error');
      }
    } catch (error) {
      Swal.fire('Error', 'No se pudo dar de baja las butacas seleccionadas.', 'error');
    } finally {
      setIsLoading(false);
    }
  }, [selectedSeats, descripcion, jwt, fetchEvento]);

  if (loading) return <GestionButacasSkeleton />;

  if (!hasPermission) {
    return <NoPermissionPage />;
  }

  return (
    <div className={styles.body}>
      <h1 className={styles.titulo}>Administrar butacas a la venta</h1>
      <h2 className={styles.subtitulo}>Evento: <span>{nombre}</span></h2>
      <div className={styles.fondoSub}>
        <p>
          Desde acá podés ver que butacas estan disponibles y <b>dar de baja</b> las que desees.
        </p>
      </div>
      {
        (eventoId === '874377791117853669' || eventoId === '8078739981077') ? (
          <>
            <div className={styles.contMapa}>
              <Teatro3deFebrero evento={evento} onSeatsSelected={handleSeatsSelected} />
            </div>

            {selectedSeats.length > 0 && (
              <>
                <SubmitButton
                  isDisabled={selectedSeats.length === 0 || isLoading}
                  color="red"
                  isLoading={isLoading}
                  onClick={() => setShowModal(true)} // Mostrar el modal de baja
                  className={styles.darDeBajaButton}
                >
                  Dar de baja
                </SubmitButton>
              </>
            )}
          </>
        ) : (
          <p className={styles.noTiene}>No tiene configurada la venta de butacas para este evento. Comuníquese con soporte para empezar a vender las butacas de su evento.</p>
        )
      }

      {error &&
        <div style={{ width: "100%", fontSize: "14px", color: "red", textAlign: "center" }}> {error} </div>}

      {/* Botón para ver el historial de bajas */}
      <button className={styles.verRegistros} onClick={fetchBajas}>Ver registros de bajas</button>

      {/* Modal para dar de baja butacas */}
      {showModal && (
        <div className={styles.modalOverlay}>
          <div className={styles.modalContent}>
            <div className={styles.modalHeader}>
              <h2 className={styles.modalTitle}>Dar de baja butacas</h2>
              <button className={styles.closeButton} onClick={() => setShowModal(false)}>X</button>
            </div>
            <div className={styles.modalBody}>
              <b>Ingresa a quien se vende o reserva:</b>
              <input
                type="text"
                placeholder='Ejemplo: "Venta a Juan Perez"'
                maxLength={20}
                value={descripcion}
                onChange={(e) => setDescripcion(e.target.value)}
              />
            </div>
            <div className={styles.modalFooter}>
              <button onClick={() => setShowModal(false)}>Cancelar</button>
              <SubmitButton
                isDisabled={isLoading || !descripcion.trim()}
                color="red"
                isLoading={isLoading}
                onClick={handleDarDeBaja}
              >
                Confirmar
              </SubmitButton>
            </div>
          </div>
        </div>
      )}

      {/* Modal para ver el historial de bajas */}
      {showHistoryModal && (
        <div className={styles.modalOverlay}>
          <div className={styles.modalContentRegistros}>
            <div className={styles.modalHeader}>
              <h2 className={styles.modalTitle}>Historial de bajas</h2>
              <button className={styles.closeButton} onClick={() => setShowHistoryModal(false)}>X</button>
            </div>
            <div className={styles.modalBody}>
              <div className={styles.tableContainer}>
                <table className={styles.table}>
                  <thead>
                    <tr>
                      <th>Descripción</th>
                      <th>Usuario</th>
                      <th>Fecha</th>
                      <th>Cantidad</th>
                      <th>Butacas</th>
                    </tr>
                  </thead>
                  <tbody className={styles.bodyTable}>
                    {bajas.length === 0 ? (
                      <tr>
                        <td colSpan="5" className={styles.errorRegistro}>No hay registros de bajas de butacas.</td>
                      </tr>
                    ) : (
                      bajas.map(baja => (
                        <tr key={baja.id}>
                          <td>{baja.descripcion}</td>
                          <td>{baja.usuario}</td>
                          <td>{new Date(baja.fechaBaja).toLocaleString()}</td>
                          <td>{baja.cantidad}</td>
                          <td>{baja.butacas}</td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className={styles.modalFooter}>
              <button onClick={() => setShowHistoryModal(false)}>Cerrar</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default GestionButacas;